import React from "react";
import { Work as WorkType } from "client";
import LargeWorkCard from "components/atoms/cards/LargeWork.card";

interface IWorkSliderProps {
  works: WorkType[];
}

function MobileWorkSlider({ works }: IWorkSliderProps): JSX.Element {
  return (
    <section className="work-slider-wrapper z-0 relative">
      <div className="work-slider">
        <div className="work-slider-inner-wrapper">
          <div className="work-slider__slides">
            {works.map((work) => (
              <div className="work-slider__slide " key={work.id}>
                <LargeWorkCard work={work} showTitle={true} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <style jsx>{`
         {
          /* .work-slider-wrapper {
          max-width: 100%;
          margin: 0 20px;
        } */
        }
      `}</style>
    </section>
  );
}

export default MobileWorkSlider;
